<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        :disabled="typeof query.customerId !== 'string' || !query.customerId"
      >
        Rediger alle
        <v-icon right> mdi-pencil-outline </v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Rediger {{ parts.length }} varer
      </v-card-title>
      
      <v-card-text>
        <v-alert type="info" text>
          Brug denne funktion varsomt. Ændringer overskriver de valgte værdier på alle varer fra den aktuelle søgning.
        </v-alert>
        <div class="text-h6">
          Vare
        </div>

        <div class="d-flex align-center" style="gap: 1rem;">
          <v-checkbox
            v-model="model.part.customProfitFactorSet"
            label="Skal ændres"
          ></v-checkbox>

          <v-text-field
            v-model.number="model.part.customProfitFactor"
            label="Fast profit %"
            :disabled="!model.part.customProfitFactorSet"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <div class="text-h6">
          Kunde
        </div>

        <div class="d-flex align-center" style="gap: 1rem;">
          <v-checkbox
            v-model="model.customerDetail.customProfitFactorSet"
            label="Skal ændres"
          ></v-checkbox>

          <v-text-field
            v-model.number="model.customerDetail.customProfitFactor"
            label="Fast profit %"
            :disabled="!model.customerDetail.customProfitFactorSet"
          ></v-text-field>
        </div>

        <div class="d-flex align-center" style="gap: 1rem;">
          <v-checkbox
            v-model="model.customerDetail.CustomListPriceDiscountSet"
            label="Skal ændres"
          ></v-checkbox>

          <v-text-field
            v-model.number="model.customerDetail.CustomListPriceDiscount"
            label="Fast listepris rabat %"
            :disabled="!model.customerDetail.CustomListPriceDiscountSet"
          ></v-text-field>
        </div>

        <v-slide-y-reverse-transition>
          <div v-if="showConfirm" class="mt-4">
            <div>
              Hvis du er helt sikker på at du vil lave disse ændringer skal du skrive: <code>ja-mange-tak</code> i feltet herunder.
            </div>
            <v-text-field
              v-model="confirmText"
              label="Er du sikker?"
            >
              <template #append-outer>
                <v-btn :disabled="confirmText !== confirmPhrase" @click="updateParts">
                  Opdater
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-slide-y-reverse-transition>

        <v-fade-transition>
          <div v-if="updating">
            <div
              v-if="progress !== 100"
              class="d-flex justify-space-between align-center"
            >
              <div>Opdaterer varer. Vent venligst..</div>
              <v-btn
                small
                @click="haltUpdate = true"
              >
                Stop
                <v-icon right> mdi-cancel </v-icon>
              </v-btn>
            </div>
            <v-progress-linear
              v-model="progress"
              height="18"
              class="mt-2"
              rounded
            >
              {{ updatedCount }}/{{ parts.length }}
            </v-progress-linear>
          </div>
        </v-fade-transition>

        <v-slide-y-transition>
          <v-alert
            v-if="progress === 100 && errorCount === 0"
            type="success"
            class="mt-3"
            text
          >
            Opdateringen er færdig. {{ updatedPartsCount }} varer blev opdateret og {{ skippedPartsCount }} blev sprunget over.
          </v-alert>
          <div v-else-if="errorCount !== 0">
            <v-alert
              type="warning"
              text
            >
              Opdatering blev færdiggjort, men {{ errorCount }} varer blev ikke opdateret korrekt!
            </v-alert>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Vis ikke-opdaterede varer
                </v-expansion-panel-header>
                <v-expansions-panel-content>
                  <v-list>
                    <v-list-item
                      v-for="part of errorParts"
                      :key="part.id"
                    >
                      <v-list-item-title>
                        {{ part.title }}
                      </v-list-item-title>
                      <v-list-item-content>
                        {{ part.partNumber }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansions-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-alert
            v-else-if="hasUpdated && errorCount === parts.length"
            type="error"
          >
            Noget gik helt galt! Ingen varer blev opdateret korrekt.
          </v-alert>
        </v-slide-y-transition>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="close">
          Luk
        </v-btn>
        <v-btn v-if="hasUpdated" @click="reset">
          Nulstil
          <v-icon right> mdi-restore </v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn  
          :disabled="showConfirm || updating || progress === 100"
          @click="showConfirm = true"
        >
          Opdater {{ parts.length }} varer
          <v-icon right color="warning"> mdi-alert-circle-outline </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PartBulkEdit',
  props: {
    query: {}
  },
  data() {
    return {
      confirmText: '',
      confirmPhrase: 'ja-mange-tak',
      dialog: false,
      loading: false,
      parts: [],
      model: this.initModel(),
      showConfirm: false,
      haltUpdate: false,
      updatedPartsCount: 0,
      skippedPartsCount: 0,
      errorParts: [],
      updating: false,
      hasUpdated: false
    }
  },
  computed: {
    updatedCount() {
      return this.updatedPartsCount + this.skippedPartsCount + this.errorParts.length;
    },
    errorCount() {
      return this.errorParts.length;
    },
    progress() {
      return (100 / this.parts?.length) * this.updatedCount;
    }
  },
  methods: {
    getParts: async function() {
      try {
        this.loading = true;

        const allQuery = {
          ...this.query,
          page: 1,
          pageSize: 100000
        };
  
        const response = await this.$AuthService.api.post('adminpart/ListSearch', allQuery);

        if (!response.data?.data) throw 'Error fetching data';
  
        this.parts = response.data.data.parts;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({})
      } finally {
        this.loading = false;
      }
    },
    updateParts: async function() {
      this.updating = true;
      this.showConfirm = false;
      this.confirmText = null;

      this.model.customerId = this.query.customerId;

      for await (const part of this.parts) {
        if (!!this.haltUpdate) {

          this.haltUpdate = false;
          break;
        }

        await this.updatePart(part)
      }

      this.updating = false;
      this.hasUpdated = true;
    },
    updatePart: async function(part) {
      const data = JSON.parse(JSON.stringify(this.model));

      // Do specific transforms
      data.part.customProfitFactor = this.model.part.customProfitFactor / 100;
      data.customerDetail.customProfitFactor = this.model.customerDetail.customProfitFactor / 100;

      try {
        const response = await this.$AuthService.api.patch(`/adminpart/${part.id}/bulk-edit`, data);

        if (!response.data.success) throw 'Failed to update part';

        if (response.data.message === 'Skipped') this.skippedPartsCount++;

        else this.updatedPartsCount++;
      } catch (error) {
        this.errorParts.push(part);
      }
    },
    initModel: function() {
      return {
        customerId: null,
        part: {
          customProfitFactor: null,
          customProfitFactorSet: false
        },
        customerDetail: {
          customProfitFactor: null,
          customProfitFactorSet: false
        }
      }
    },
    close: function() {
      this.reset();

      this.dialog = false;
    },
    reset: function() {
      this.model = this.initModel();
      this.confirmText = '';
      this.showConfirm = false;
      this.updatedPartsCount = 0;
      this.skippedPartsCount = 0;
      this.errorParts = [];
      this.updating = false;
    }
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.getParts()
      }
    }
  }
}
</script>