<template>
  <v-container fluid>
    <div>
      <v-row>
        <v-col>
          <v-toolbar
            color="transparent"
            flat
          >
            <v-card-title class="pl-0">
              {{ $t("admin.part.list.title") }}
            </v-card-title>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="model.query.textSearch"
              :label="`Søg ${ model.data.partsCount ? `blandt ${model.data.partsCount} varer` : ''}`"
              :disabled="loading"
              hide-details
              autofocus
              placeholder="Skriv for at søge"
              solo
              @keyup.enter="search"
            >
              <template #append>
                <v-checkbox v-model="model.query.deepSearch" label="Udvidet tekstsøgning" hide-details></v-checkbox>
              </template>
            </v-text-field>

            <ListSearchButton
              class="ml-3"
              :loading="loading || searching"
              @click="search"
            />
            
            <v-spacer></v-spacer>

            <div class="d-flex align-center" style="gap: .5rem">
              <v-btn-toggle v-model="viewMode">
                <v-btn icon small value="table">
                  <v-icon small>
                    mdi-menu
                  </v-icon>
                </v-btn>
                <v-btn icon small value="card">
                  <v-icon small>
                    mdi-card-outline
                  </v-icon>
                </v-btn>
              </v-btn-toggle>

              <PartBulkEdit :query="model.query" />

              <v-btn to="create" append text>
                Ny vare
                <v-icon right> mdi-plus </v-icon>
              </v-btn>
            </div>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row
        align="start"
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
          style="position: sticky; top: 80px;"
        >
          <ListFilters
            :loading="loading || searching"
            :itemCount="model.data.partsCount"
            @click:reset="clearFilters"
            @click:search="search"
          >
            <v-autocomplete
              v-model="model.query.manufacturers"
              :items="filterManufacturers"
              item-text="text"
              item-value="value"
              :label="$t('search.manufacturers')"
              return-object
              :search-input.sync="manufacturerInput"
              :loading="loading || searching"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.suppliers"
              :items="filterSuppliers"
              item-text="text"
              item-value="value"
              :label="$t('search.suppliers')"
              return-object
              :search-input.sync="supplierInput"
              :loading="loading || searching"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.customerId"
              :items="model.metadata.customers"
              item-text="text"
              item-value="value"
              :label="$t('search.customers')"
              :search-input.sync="customerInput"
              :loading="loading || searching"
              auto-select-first
              small-chips
              deletable-chips
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.categoryGroups"
              :items="filterCategoryGroups"
              item-text="text"
              item-value="value"
              :label="$t('search.groups')"
              return-object
              :search-input.sync="categoryGroupInput"
              :loading="loading || searching"
              auto-select-first
              small-chips
              dense-line
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.query.categories"
              :items="filterCategories"
              item-text="text"
              item-value="value"
              :label="$t('search.categories')"
              return-object
              :search-input.sync="categoryInput"
              :loading="loading || searching"
              auto-select-first
              small-chips
              deletable-chips
              multiple
              outlined
              dense
            />
            <v-select
              v-model="model.query.prefixes"
              :items="[ 'O', 'F', 'L' ]"
              label="Varetype (prefix)"
              :loading="loading || searching"
              multiple
              small-chips
              clearable
              outlined
              dense
            />
            <v-checkbox
              v-model="model.query.favoritesOnly"
              label="Kun favoritter"
              hide-details
              outlined
              dense
            />
            <v-checkbox
              v-model="model.query.isPreferredByCustomerOnly"
              label="Foretrukket af kunde"
              @change="search"
              hide-details
              outlined
              dense
            />
          </ListFilters>
        </v-col>

        <v-col>
          <v-card v-if="viewMode === 'table'" class="flex-grow-1">
            <v-data-table
              :headers="headers"
              :items="parts"
              item-key="partNumber"
              :options.sync="tableOptions"
              :loading="loading || searching"
              loading-text="Indlæser materialer... Vent venligst"
              :footer-props="footerProps"
              show-expand
              :server-items-length="model.data.partsCount"
              @dblclick:row="$router.push(`/parts/${$event.item.id}/edit`)"
              @update:items-per-page="search"
              @update:page="search"
              @update:sort-by="search"
              class="part-table"
            >
              <template #[`item.description`]="{ item }">
                {{ item.description.slice(0, 60)
                }}<span v-if="item.description.length > 60">...</span>
              </template>
              <template #[`item.groupTitle`]="{ item }">
                {{ item.groupTitle }}
                <v-icon small class="ml-1">
                  {{ item.groupIcon }}
                </v-icon>
              </template>
              <template #[`item.customerPrice`]="{ item }">
                {{ item.customerPrice | formatNumberFixed2 }}
              </template>
              <template #[`item.listPrice`]="{ item }">
                {{ item.listPrice | formatNumberFixed2 }}
              </template>
              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  {{ item }}
                </td>
              </template>
            </v-data-table>
          </v-card>

          <div v-else-if="viewMode === 'card'" class="flex-grow-1">
            <v-progress-linear
              :active="(loading || searching) && !!parts.length"
              indeterminate
            />

            <v-data-iterator
              :items="parts"
              :loading="loading || searching"
              :options.sync="tableOptions"
              loading-text="Indlæser materialer... Vent venligst"
              :footer-props="footerProps"
              :server-items-length="model.data.partsCount"
              @update:items-per-page="search"
              @update:page="search({ page: $event })"
              @update:sort-by="search"
            >
              <template #default="{ items }">
                <v-fade-transition group>
                  <v-row v-for="item in items" :key="item.id">
                    <v-col>
                      <v-card>
                        <v-card-title class="text-subtitle-1">
                          {{ item.title }}

                          <v-spacer></v-spacer>

                          <v-chip
                            v-if="item.status !== 'Active'"
                            label
                            class="mr-3"
                            :color="getStatusColor(item.status)"
                          >
                            {{ getStatusText(item.status) }}
                          </v-chip>

                          <v-btn text :to="`${item.id}/edit`" append>
                            <v-icon>
                              mdi-pencil-outline
                            </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col cols="2">
                              <v-lazy>
                                <v-img
                                  :src="item.imageUrl"
                                  :lazy-src="require('@/assets/missing-image.png')"
                                  height="150"
                                  contain
                                ></v-img>
                              </v-lazy>
                            </v-col>
                            <v-col cols="4">
                              <v-simple-table dense>
                                <tbody>
                                  <template v-for="(detail, i) in getPartDetails(item)">
                                    <tr
                                      v-if="detail.value"
                                      :key="i"
                                    >
                                      <td> {{ detail.text }} </td>
                                      <td class="text-right">
                                        <clipboard-copy v-if="detail.copy">
                                          {{ detail.value }}
                                        </clipboard-copy>

                                        <span v-else>
                                          {{ detail.value }}
                                        </span>
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </v-simple-table>
                            </v-col>
                            <v-col>
                              <div>
                                <v-data-table
                                  :headers="supplierHeaders"
                                  :items="item.suppliers"
                                  hide-default-footer
                                  dense
                                >
                                  <template #[`item.title`]="{ item }">
                                    <div class="d-flex align-center">
                                      <span>
                                        {{ item.title }}
                                      </span>
                                      <v-icon v-if="item.usedInListPriceCalculation" right small>
                                        mdi-crown-outline
                                      </v-icon>
                                    </div>
                                  </template>
                                  <template #[`item.typeReference`]="{ item }">
                                    <clipboard-copy>
                                      {{ item.typeReference }}
                                    </clipboard-copy>
                                  </template>
                                  <template #[`item.costPrice`]="{ item }">
                                    <PartSupplierPrice :price="item.costPrice" :confirmedOn="item.priceConfirmedOn" :stalePriceLimitInDays="item.stalePriceLimitInDays" />
                                  </template>
                                  <template #[`item.listPrice`]="{ item }">
                                    {{ item.listPrice | formatNumberFixed2 }}
                                  </template>
                                </v-data-table>
                              </div>
                              <div class="mt-3">
                                <v-data-table
                                  :headers="customerHeaders"
                                  :items="item.customers"
                                  hide-default-footer
                                  dense
                                >
                                  <template #[`item.isPreferredByCustomer`]="{ item }">
                                    <v-icon
                                      v-if="item.isPreferredByCustomer"
                                      color="yellow"
                                    >
                                      mdi-star
                                    </v-icon>
                                  </template>
                                  <template #[`item.isFavorite`]="{ item }">
                                    <v-icon
                                      v-if="item.isFavorite"
                                      color="yellow"
                                    >
                                      mdi-star
                                    </v-icon>
                                  </template>
                                  <template #[`item.price`]="{ item }">
                                    <PartCustomerPriceDetails
                                      :price="item.price"
                                      :calculated-on="item.priceCalculatedOn"
                                      :calculation-criteria="item.priceCalculationCriteria"
                                      :fixed-until="item.priceFixedUntil"
                                    />
                                  </template>
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <template v-if="item.internalNote">
                          <v-card-text>
                            <div> Note </div>
                            <div class="text-caption">{{ item.internalNote }}</div>
                          </v-card-text>
                        </template>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-fade-transition>
              </template>

              <template #loading>
                <v-row
                  v-for="n in 4"
                  :key="n"
                >
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-skeleton-loader type="card-heading" width="60%" />

                        <v-spacer></v-spacer>

                        <v-skeleton-loader type="button" />
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col cols="2">
                            <v-skeleton-loader type="image" />
                          </v-col>

                          <v-col cols="4">
                            <v-skeleton-loader type="table-heading@3" />
                          </v-col>

                          <v-col>
                            <v-skeleton-loader type="table-heading@3" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import PartUpsertDialog from '@/components/part/PartUpsertDialog';
import ClipboardCopy from '@/components/ClipboardCopy';
import ListFilters from '@/components/ListFilters.vue';
import ListSearchButton from '@/components/ListSearchButton.vue';
import PartBulkEdit from '@/components/part/PartBulkEdit.vue';
import PartSupplierPrice from '@/components/PartSupplierPrice.vue';
import PartCustomerPriceDetails from '@/components/part/PartCustomerPriceDetails.vue'

var searchTimeout = null;

export default {
  name: "PartList",
  components: {
    PartUpsertDialog,
    ClipboardCopy,
    ListFilters,
    ListSearchButton,
    PartBulkEdit,
    PartSupplierPrice,
    PartCustomerPriceDetails
  },
  data() {
    return {
      model: {
        data: {},
        metadata: {},
        query: {}
      },
      filterDrawer: true,
      loading: false,
      searching: false,
      viewMode: 'card',
      bulkEditDialog: false,
      bulkEditModel: {
        part: {
          fixedProfit: null
        },
        partCustomerDetail: {
          fixedProfit: null
        }
      },
      manufacturerInput: null,
      categoryGroupInput: null,
      categoryInput: null,
      customerInput: null,
      supplierInput: null,
      tableOptions: {
        itemsPerPage: 25
      },
      footerProps: {
        itemsPerPageOptions: [10, 25, 50],
      },
      customerHeaders: [
        { text: 'Kundenavn', value: 'initials' },
        { text: 'Varenummer', value: 'partNumber' },
        { text: this.$t('parts.customerFavorite'), value: 'isPreferredByCustomer', sortable: false, align: 'center' },
        { text: this.$t('parts.tpaFavorite'), value: 'isFavorite', sortable: false, align: 'center' },
        { text: 'Pris', value: 'price', align: 'end' }
      ],
      supplierHeaders: [
        { text: 'Leverandør', value: 'title' },
        { text: 'Producent', value: 'manufacturerTitle' },
        { text: 'Typebetegnelse', value: 'typeReference' },
        { text: 'Indkøbspris', value: 'costPrice', align: 'end' },
        { text: 'Pris', value: 'listPrice', align: 'end' }
      ]
    };
  },
  computed: {
    parts() {
      return this.model.data.parts ?? [];
    },
    headers() {
      let headers = [
        { text: "Navn", value: "title" },
        { text: "Kategori", value: "groupTitle", sortable: false },
        // { text: "Produktnummer", value: "partNumber" },
        { text: 'EAN', value: 'ean', sortable: false },
        { text: "Producent", value: "manufacturerTitle", sortable: false },
        { text: "TPA listepris (DKK /stk.)", value: "listPrice", align: 'end' },
        { text: '', value: 'data-table-expand' }
      ];

      if (this.model.query.customerId) {
        headers.push(
          { text: 'Kundepris', value: 'customerPrice' },
          { text: 'Kundevarenummer', value: 'customerPartNumber', sortable: false }
        );
      }

      return headers;
    },
    filterManufacturers() {
      return [...new Set(this.model.query.manufacturers?.concat(this.model.metadata?.manufacturers))];
    },
    filterSuppliers() {
      return [...new Set(this.model.query.suppliers?.concat(this.model.metadata.suppliers))];
    },
    filterCategoryGroups() {
      return [...new Set(this.model.query.categoryGroups?.concat(this.model.metadata.categoryGroups))];
    },
    filterCategories() {
      return [...new Set(this.model.query.categories?.concat(this.model.metadata.categories))];
    },
  },
  mounted() {
    if (!localStorage.getItem('tpa:admin-part-query')) {
      this.getInitList();
    } else {
      this.model.query = JSON.parse(localStorage.getItem('tpa:admin-part-query'));
      this.model.query.textSearch = '';
      this.search();
    }
  },
  methods: {
    async getInitList() {
      try {
        this.loading = true;

        const response = await this.$AuthService.api.get("adminpart/ListInitSearch", { params: this.$route.query });

        this.model = response.data;
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Kunne ikke hente materialer' });
      } finally {
        this.loading = false;
      }
    },

    search({ page = 1 } = {}) {
      const { itemsPerPage, sortBy } = this.tableOptions;

      this.model.query.pageSize = itemsPerPage;
      this.model.query.page = page;
      this.tableOptions.page = page;

      if (sortBy?.length) {
        switch (sortBy[0]) {
          case 'description':
            this.model.query.sorting = 'GroupCategoryPartTitle';
            break;

          case 'partNumber':
            this.model.query.sorting = 'PartNumber';
            break;

          case 'price':
            this.model.query.sorting = 'GroupCategoryPrice';
            break;
        
          default:
            this.model.query.sorting = '';
            break;
        }
      }

      // Save query to localStorage
      localStorage.setItem('tpa:admin-part-query', JSON.stringify(this.model.query));

      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        this.searching = true;
        this.$vuetify.goTo(0); // Scroll to the top

        this.$AuthService.api
          .post('adminpart/ListSearch', this.model.query)
          .then((response) => {
            this.model.data = response.data.data;
            this.model.metadata = response.data.metadata;
            // this.model.metadata.manufacturers = response.data.metadata.manufacturers;
            // this.model.metadata.categoryGroups = response.data.metadata.categoryGroups;
            // this.model.metadata.categories = response.data.metadata.categories;
            // this.model.metadata.customers = response.data.metadata.customers;
            // this.model.metadata.suppliers = response.data.metadata.suppliers;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.searching = false;
          });
      }, 450);
    },
    getPartDetails(part) {
      const {
        ean,
        partNumber,
        manufacturerTitle,
        manufacturerTypeReference,
        groupTitle,
        categoryTitle,
        detail,
        packageSize,
        dangerousGoodsIndicatorProfile
      } = part;

      return [
        { text: 'EAN', value: ean ?? '-', copy: !!ean },
        { text: 'Vare nr.', value: partNumber, copy: true },
        { text: 'Producent', value: manufacturerTitle },
        { text: 'Typebetegnelse', value: manufacturerTypeReference },
        { text: 'Gruppe', value: groupTitle },
        { text: 'Kategori', value: categoryTitle },
        { text: 'Detalje', value: detail },
        { text: 'Pakkestr.', value: packageSize },
        { text: 'Farligt gods', value: dangerousGoodsIndicatorProfile }
      ];
    },
    async getImage(url) {
      try {
        await url;

        return url;
      } catch (error) {
        return 'fav.png';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'PhaseOut':
          return 'warning';

        case 'EndOfLife':
          return 'error';

        default:
          return 'default';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 'PhaseOut':
          return 'Udgår';

        case 'EndOfLife':
          return 'Udgået';

        default:
          return status;
      }
    },

    clearFilters() {
      this.getInitList();
    }
  }
};
</script>

<style>
.part-table.v-data-table tr {
  cursor: pointer;
}
</style>
 