<template>
  <v-tooltip :disabled="!confirmedOn" bottom nudge-right="42">
    <template #activator="{ attrs, on }">
      <div v-bind="attrs" v-on="on" class="d-flex align-center justify-end" style="gap: 0.5rem;">
        <v-icon v-if="priceIsOld" small color="warning">mdi-alert</v-icon>
        <div>{{ price | formatNumberFixed2 }}</div>
      </div>
    </template>

    <p v-if="priceIsOld">
      <v-icon small color="warning">mdi-alert</v-icon>
      Prisen er opdateret for <b>mere end {{ limit }}</b> dage siden og <i>kan</i> være gammel.
    </p>

    <div>
      Pris modtaget: <b>{{ age }}</b> ({{ confirmedOn | formatDateTime }})
    </div>
  </v-tooltip>
</template>

<script>
import config from '@/assets/config.json';

const defaultStaleLimit = config.supplierCostPriceStaleDays;

export default {
  name: 'PartSupplierPrice',
  props: {
    price: {
      type: Number,
      required: true
    },
    confirmedOn: {
      type: String
    },
    stalePriceLimitInDays: {
      type: Number
    }
  },
  computed: {
    age() {
      this.moment.updateLocale('da', {})
      return this.moment(this.confirmedOn).fromNow()
    },
    limit() {
      return this.stalePriceLimitInDays ?? defaultStaleLimit
    },
    priceIsOld() {
      const difference = new Date() - new Date(this.confirmedOn)
      const differenceInDays = difference / 86400000

      // If more than stale limit old
      return differenceInDays > this.limit
    }
  }
}
</script>