<template>
  <v-dialog v-model="dialog" persistent scrollable width="600">
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="editButton"
        outlined
        small
        fab
        right
        color="primary"
        v-bind="attrs"
        v-on="on"
        @click="dialogInit"
      >
        <v-icon dark> mdi-pencil </v-icon>
      </v-btn>
      <v-btn v-else dark color="primary" v-bind="attrs" v-on="on" @click="dialogInit">
        Tilføj
        <v-icon right> mdi-plus </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="title white--text secondary">
        {{
          isEdit
            ? $t("parts.upsert.titleEdit", { partNumber: partNumber })
            : $t("parts.upsert.titleCreate")
        }}
      </v-card-title>

      <Loading :loading="!loaded" />

      <v-card-text>
        <v-row v-if="loaded && model" class="part-upsert-tpacard">
          <v-col>
            <v-textarea
              v-model="model.data.description"
              rows="1"
              :label="$t('parts.upsert.description')"
              outlined
              auto-grow
            />
            <v-textarea
              v-model="model.data.internalNote"
              rows="1"
              :label="$t('parts.upsert.internalNote')"
              outlined
              auto-grow
            />
            <v-autocomplete
              v-model="model.data.groupId"
              :items="model.metadata.groups"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.group')"
              auto-select-first
              small-chips
              clearable
              outlined
              dense
            />
            <v-autocomplete
              v-model="model.data.categoryId"
              :items="filteredCategories"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.category')"
              auto-select-first
              small-chips
              outlined
              dense
            />
            <v-text-field
              v-model="model.data.ean"
              type="text"
              :label="$t('parts.upsert.ean')"
              outlined
              single-line
              dense
            />
            <v-autocomplete
              v-model="model.data.manufacturerId"
              :items="model.metadata.manufacturers"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.manufacturer')"
              auto-select-first
              small-chips
              outlined
              dense
            />
            <v-text-field
              v-model="model.data.manufacturerPartNumber"
              type="text"
              :label="$t('parts.upsert.manufacturerPartNumber')"
              outlined
              single-line
              dense
            />
            <v-autocomplete
              v-model="model.data.partUnitId"
              :items="model.metadata.partUnits"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.partUnit')"
              auto-select-first
              small-chips
              outlined
              dense
            />
            <v-text-field
              v-model="model.data.customListPrice"
              type="number"
              :label="$t('parts.upsert.customListPrice')"
              outlined
              single-line
              dense
            />
            <v-text-field
              v-model="model.data.customProfitFactor"
              type="number"
              :label="$t('parts.upsert.customProfitFactor')"
              hint="Prioriteres højere end alle andre angivede profit procenter"
              outlined
              single-line
              dense
            />

            <hr />
            <br />
            <v-autocomplete
              v-model="model.data.supplierId"
              :items="model.metadata.suppliers"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.supplier')"
              auto-select-first
              small-chips
              outlined
              dense
            />
            <v-text-field
              v-model="model.data.supplierPrice"
              type="number"
              :label="$t('parts.upsert.supplierPrice')"
              outlined
              single-line
              dense
            />
            <v-text-field
              v-model="model.data.supplierListPrice"
              type="number"
              :label="$t('parts.upsert.supplierListPrice')"
              outlined
              single-line
              dense
            />

            <hr />
            <br />
            <v-autocomplete
              v-model="model.data.customerId"
              :items="model.metadata.customers"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.customer')"
              auto-select-first
              small-chips
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-model="model.data.customerPartCategoryId"
              :items="filteredCustomerPartCategories"
              item-text="text"
              item-value="value"
              :label="$t('parts.upsert.customerPartCategory')"
              auto-select-first
              small-chips
              outlined
              dense
            ></v-autocomplete>
            <v-checkbox
              v-model="model.data.isFavorite"
              :label="$t('parts.upsert.isFavorite')"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn v-if="isEdit" outlined @click="dialogDelete">
          {{ $t("parts.upsert.submitDelete") }}
          <v-icon right> mdi-delete </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn outlined color="primary" @click="dialogCancel">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="secondary" :loading="saving" :disabled="!isValid" @click="dialogSave">
          {{
            isEdit ? $t("parts.upsert.submitEdit") : $t("parts.upsert.submitCreate")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  name: "PartUpsertDialog",
  components: {
    Loading,
  },
  props: {
    editButton: {
      default: false,
      type: Boolean,
    },
    partId: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      model: Object,
      dialog: false,
      loaded: false,
      saving: false,
      isValid: false,
    };
  },
  computed: {
    filteredCategories() {
      if (this.model.data.groupId) {
        return this.model.metadata.categories.filter((x) => x.groupId === this.model.data.groupId);
      }
      return this.model.metadata.categories;
    },
    filteredCustomerPartCategories() {
      if (this.model.data.customerId) {
        return this.model.metadata.customerPartCategories.filter(
          (x) => x.customerId === this.model.data.customerId
        );
      }
      return this.model.metadata.customerPartCategories;
    },
    isEdit() {
      return this.partId;
    },
    partNumber() {
      return this.model?.data?.partNumber;
    },
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.model.data) {
          const { description, categoryId, manufacturerId, partUnitId, supplierId, supplierPrice } =
            this.model.data;
          this.isValid = !!(
            description &&
            categoryId &&
            manufacturerId &&
            partUnitId &&
            (!supplierId || supplierPrice)
          );
        }
      },
    },
  },
  methods: {
    dialogCancel() {
      this.dialog = false;
    },
    async dialogDelete() {
      if (confirm(this.$t("parts.upsert.confirmDelete"))) {
        if (!this.saving) {
          this.saving = true;
          try {
            const response = await this.$AuthService.api.delete(`/AdminPart/${this.partId}`);
            if (response.data.success) {
              this.$emit("deleted");
              this.dialog = false;
              this.$router.push("/parts");
            }
          } finally {
            this.saving = false;
          }
        }
      }
    },
    async dialogInit() {
      this.loaded = false;
      const url = this.partId ? `AdminPart/${this.partId}/Edit` : "AdminPart/Create";
      const response = await this.$AuthService.api.get(url);
      this.model = response.data;
      this.loaded = true;
    },
    async dialogSave() {
      if (!this.saving) {
        this.saving = true;
        try {
          const { requestUrl, requestMethod } = this.isEdit
            ? { requestUrl: `/AdminPart/${this.partId}`, requestMethod: this.$AuthService.api.put }
            : { requestUrl: `/AdminPart/Create`, requestMethod: this.$AuthService.api.post };
          const response = await requestMethod(requestUrl, this.model.data);

          if (response.data.success) {
            this.$emit("saved");
          }
        } finally {
          this.saving = false;
          this.dialog = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.part-upsert-tpacard {
  padding-top: 2em;
}
.part-upsert-tpacard.v-sheet--outlined {
  border: none;
}
</style>
